import React, { useState } from "react";
import Sidebar from "../components/Sidebar/Sidebar";
import Chat from "./Chat/Chat";
import Header from "./Header/Header";
import { useUsers } from "../hooks/useUsers";
import Loader from "../components/Loader";

export const Home: React.FC = () => {
  console.log((window as any).Telegram?.WebApp);
  const { isLoading, data } = useUsers();

  const [sidebarOpen, setSidebarOpen] = useState(false);

  const onBurgerClick = () => {
    setSidebarOpen((prev) => !prev);
  };

  console.log(process.env.REACT_APP_ENVIRONMENT);

  const userId =
    process.env.REACT_APP_ENVIRONMENT === "development"
      ? 275919742
      : (window as any).Telegram?.WebApp?.initDataUnsafe?.user?.id;

  return (
    <div className="bg-backgroundPrimary w-[100vw] h-[100vh] flex">
      {isLoading ? (
        <div className="flex justify-center w-full items-center">
          <Loader />
        </div>
      ) : userId ? (
        data.find((item: any) => item.userId === userId) ? (
          <>
            <Sidebar
              isOpen={sidebarOpen}
              onCloseClick={() => setSidebarOpen(false)}
            />
            <div
              className={`bg-black w-full h-full fixed left-0 top-0 z-10 transition-opacity ${
                sidebarOpen ? "opacity-[0.3]" : "opacity-0 pointer-events-none"
              }`}
              onClick={() => setSidebarOpen(false)}
            />
            <Header onBurgerClick={onBurgerClick} />
            <Chat />
          </>
        ) : (
          <div className="flex justify-center w-full items-center flex-col">
            <p className="text-white">У вас нет доступа к этому боту</p>
            <p className="text-white">
              Передайте этот код для получения доступа:{" "}
            </p>
            <p className="text-white bg-black px-3 py-0.5 rounded-md mt-2">
              {(window as any).Telegram?.WebApp?.initDataUnsafe?.user?.id}
            </p>
          </div>
        )
      ) : (
        <div className="flex justify-center w-full items-center">
          <p className="text-white">
            Приложение доступно только через Telegram
          </p>
        </div>
      )}
    </div>
  );
};
