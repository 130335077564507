import React from "react";
import { ReactComponent as BurgerIcon } from "../../assets/svg/burger.svg";
import { ReactComponent as PlusIcon } from "../../assets/svg/plus.svg";
import styles from "./styles.module.css";

const Header: React.FC<{ onBurgerClick: () => void }> = ({ onBurgerClick }) => {
  return (
    <div className="fixed z-10 border-b-[1px] w-full h-[40px] flex justify-between items-center px-2">
      <button className={styles["icon-wrapper"]} onClick={onBurgerClick}>
        <BurgerIcon width="24px" height="24px" />
      </button>
      <button className={styles["icon-wrapper"]}>
        <PlusIcon width="24px" height="24px" />
      </button>
    </div>
  );
};

export default Header;
