import React from "react";
import "./App.css";
import { Home } from "./pages/Home";
import { QueryClient, QueryClientProvider } from "react-query";

const queryClient = new QueryClient();

const colorObject = {
  bg_color: "#1d2733",
  secondary_bg_color: "#151e27",
  text_color: "#ffffff",
  hint_color: "#7d8b99",
  link_color: "#5eabe1",
  button_color: "#50a8eb",
  button_text_color: "#ffffff",
};

/*{
    "initData":"query_id=AAF-M3IQAAAAAH4zchDyE4gI&user=%7B%22id%22%3A275919742%2C%22first_name%22%3A%22Andrey%22%2C%22last_name%22%3A%22%22%2C%22username%22%3A%22collideadron%22%2C%22language_code%22%3A%22ru%22%2C%22is_premium%22%3Atrue%7D&auth_date=1685831632&hash=9028bdf1cb3eac1fb483aa33f732bb4d2e6fb76b28b6f9ae860ae533052fa0dc",
    "initDataUnsafe": {
      "query_id":"AAF-M3IQAAAAAH4zchDyE4gI",
      "user": {
        "id":275919742,
        "first_name":"Andrey",
        "last_name":"",
        "username":"collideadron",
        "language_code":"ru",
        "is_premium":true
      },
      "auth_date":"1685831632",
          "hash":"9028bdf1cb3eac1fb483aa33f732bb4d2e6fb76b28b6f9ae860ae533052fa0dc"
    },
    "version":"6.7",
    "platform":"tdesktop",
    "colorScheme":"dark",
    "themeParams":{
    "bg_color":"#17212b",
        "button_color":"#5288c1",
        "button_text_color":"#ffffff",
        "hint_color":"#708499",
        "link_color":"#6ab3f3",
        "secondary_bg_color":"#232e3c",
        "text_color":"#f5f5f5"
},
    "isExpanded":true,
    "viewportHeight":496,
    "viewportStableHeight":496,
    "isClosingConfirmationEnabled":false,
    "headerColor":"#17212b",
    "backgroundColor":"#17212b",
    "BackButton":{
    "isVisible":false
},
    "MainButton":{
    "text":"CONTINUE",
        "color":"#5288c1",
        "textColor":"#ffffff",
        "isVisible":false,
        "isProgressVisible":false,
        "isActive":true
},
    "HapticFeedback":{

}
}*/

function App() {
  return (
    <QueryClientProvider client={queryClient}>
      <Home />
    </QueryClientProvider>
  );
}

export default App;
