import React from "react";
import Lottie from "react-lottie-player";
import lottieJson from "../assets/loader.json";

const Loader: React.FC = () => {
  return (
    <Lottie
      loop
      animationData={lottieJson}
      play
      style={{ width: 150, height: 150 }}
    />
  );
};

export default Loader;
