import React from "react";
import styles from "./styles.module.css";
import Message from "../../components/Message";

const Chat: React.FC = () => {
  return (
    <div className="flex justify-center w-full h-full flex-col items-center overflow-x-hidden relative pt-[40px]">
      <div className="w-full overflow-y-auto pb-[100px]">
        <Message isUser />
        <Message />
        <Message isUser />
        <Message />
        <Message isUser />
        <Message />
        <Message isUser />
        <Message />
        <Message isUser />
        <Message />
        <Message isUser />
        <Message />
        <Message isUser />
        <Message />
      </div>
      <div className={styles["input-container"]}>
        <textarea className={styles["input"]} />
      </div>
      <div className={styles["fade"]} />
    </div>
  );
};

export default Chat;
