import React from "react";
import andreyGpt from "../assets/andreygpt.jpg";

type Props = {
  isUser?: boolean;
};

const Message: React.FC<Props> = ({ isUser }) => {
  return (
    <div
      className={`w-full ${
        isUser ? "text-right" : "bg-[#444654] border-t-[1px]"
      } border-t-[rgba(0,0,0,0.4)] max-w-[600px] m-auto px-2`}
    >
      <div className="w-full max-w-xl m-auto py-4 flex items-start">
        {!isUser && (
          <img
            src={andreyGpt}
            alt="andreyGPT"
            className="w-[32px] h-[32px] mr-6 rounded-[4px]"
          />
        )}
        <p className={isUser ? "text-white" : "text-[#92c7e7]"}>
          Hello! It seems like you entered "dsf." Is there something specific
          you would like to know or discuss? I'm here to help!
        </p>
        {isUser && (
          <img
            src={andreyGpt}
            alt="andreyGPT"
            className="w-[32px] h-[32px] ml-6 rounded-[4px]"
          />
        )}
      </div>
    </div>
  );
};

export default Message;
