import React from "react";
import { ReactComponent as CloseIcon } from "../../assets/svg/close.svg";
import styles from "./styles.module.css";

const Sidebar: React.FC<{ isOpen: boolean; onCloseClick: () => void }> = ({
  isOpen,
  onCloseClick,
}) => {
  return (
    <div
      className={`bg-[#202123] w-[240px] h-full flex justify-center items-start p-2 absolute z-20 transition-all ${
        isOpen ? "left-0" : "left-[-100%]"
      }`}
    >
      <button
        className={`absolute right-[-30px] top-[10px] ${styles["icon-wrapper"]}`}
        onClick={onCloseClick}
      >
        <CloseIcon width="24" height="24" />
      </button>
      <div className="flex flex-col justify-between w-full h-full items-center">
        <button className="text-white bg-[#25262a] w-full py-1">
          Добавить чат
        </button>
      </div>
    </div>
  );
};

export default Sidebar;
