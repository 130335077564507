import { useQuery } from "react-query";

export const useUsers = () => {
  const fetchData = async () => {
    const data = await fetch(`${process.env.REACT_APP_BACKEND_URL}/user`);

    const parsedData = await data.json();

    return parsedData;
  };

  return useQuery(["getUsers"], fetchData);
};
